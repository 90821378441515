import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./routing/AppRouter";
import {
  AuthContext,
  AuthProvider,
  IAuthContext,
  TAuthConfig,
} from "react-oauth2-code-pkce";
import React, { useContext } from "react";
import { Box } from "@mui/material";
import TopNavBar from "./components/Nav/TopNavBar";
import Footer from "./components/Nav/Footer";

const loginBaseUrl: string = process.env.REACT_APP_LOGIN_ENDPOINT || "";
const ownBaseUrl: string = process.env.REACT_APP_OWN_ADDRESS || "";

console.log(process.env.REACT_APP_LOGIN_ENDPOINT);

const authConfig: TAuthConfig = {
  clientId: "rbac_front_end",
  authorizationEndpoint: loginBaseUrl + "/oauth2/v1/authorize",
  tokenEndpoint: loginBaseUrl + "/oauth2/v1/token",
  redirectUri: ownBaseUrl,
  scope: "openid profile rbac",
  logoutEndpoint: loginBaseUrl + "/oauth2/v1/logout",
  logoutRedirect: ownBaseUrl,
  autoLogin: true,
  storage: "session",
  storageKeyPrefix: "hygiaso_",
  refreshWithScope: true,
};

console.log(authConfig);

function App() {
  useContext<IAuthContext>(AuthContext);

  return (
    <AuthProvider authConfig={authConfig}>
      <Router>
        <Box className="container">
          <Box component="header" sx={{ flexShrink: 0 }}>
            <TopNavBar />
          </Box>
          <Box
            component="main"
            sx={{ height: "70vh", overflow: "auto" }}
          >
            <AppRouter />
          </Box>
        </Box>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
