import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, IApiError } from "./http.types";
import { HttpVerbs } from "./http.constants";



const httpRequest = async <T>(
  method: HttpVerbs,
  url: string,
  token: string,
  paramsOrData?: unknown,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T, IApiError>> => {
  // console.log(token);
  const requestConfig: AxiosRequestConfig = {
    ...config,
    url,
    method,
    params: method === HttpVerbs.GET ? paramsOrData : undefined,
    data: method !== HttpVerbs.GET ? paramsOrData : undefined,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token ? token : "undefined"}`,
      "Content-Type": paramsOrData ? "application/json" : undefined,
    },
  };

  try {
    const response: AxiosResponse<T> = await axios(requestConfig);
    return {
      data: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        error: {
          message: error.response?.data?.description || error.message,
        },
        statusCode: error.response?.status || 500,
      };
    } else {
      return {
        error: {
          message: "An unexpected error occurred",
        },
        statusCode: 500,
      };
    }
  }
};

export const http = {
  get: async <T>(
    url: string,
    token: string,
    params?: unknown,
    config?: AxiosRequestConfig,
  ) => httpRequest<T>(HttpVerbs.GET, url, token, params, config),
  put: async <T>(
    url: string,
    token: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => httpRequest<T>(HttpVerbs.PUT, url, token, data, config),
  patch: async <T>(
    url: string,
    token: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => httpRequest<T>(HttpVerbs.PATCH, url, token, data, config),
  post: async <T>(
    url: string,
    token: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => httpRequest<T>(HttpVerbs.POST, url, token, data, config),
  delete: async <T>(url: string, token: string, config?: AxiosRequestConfig) =>
    httpRequest<T>(HttpVerbs.DELETE, url, token, null, config),
};
