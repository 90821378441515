import React, { useEffect } from 'react'
import PropTypes from "prop-types";

const ExternalRedirect = ({ url }) => {
    useEffect(() => {
        window.location.replace(url);
    }, [url]);

    return <p>Redirecting...</p>;
};

ExternalRedirect.propTypes = {
    url: PropTypes.string.isRequired,
};

export default ExternalRedirect;