import { IUser } from "../models/user.types";

export const getListOfContacts = (
  user: IUser | undefined,
): { type: string; contact: string }[] => {
  if (!user?.confirmedContacts) return [];
  const rv: { type: string; contact: string }[] = [];
  for (const type in user?.confirmedContacts) {
    for (const contact of user.confirmedContacts[type])
      rv.push({ type, contact });
  }
  return rv;
};

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

