import React, {useCallback, useContext, useEffect} from "react";
import { useTranslation } from "react-i18next";
import OrgStore from "../stores/OrgStore";
import {Button, Chip, List, ListItem, Stack, TextField, Typography} from "@mui/material";
import { assignableRoleToResourceName } from "src/stores/ResourcesStore";
import {AuthContext} from "react-oauth2-code-pkce";
import ResourceStore from "../stores/ResourcesStore"



const CurrentOrganizationDetails: React.FC = () => {
  const { t } = useTranslation();
  const {token} = useContext(AuthContext)
  const organizationDetails = OrgStore.currentOrganisation;
  const [editFieldsMap, setEditFieldsMap] = React.useState<Map<string, string>>(new Map<string, string>());
  const [subresourcesMap, setSubresourcesMap] = React.useState<Map<string, string[]>>(new Map<string, string[]>());

  useEffect(() => {
    const fillSubresourceMap = async (resourceList: string[], token: string) => {
      const subresourceMap = new Map<string, string[]>
      for (const resource of resourceList) {
        subresourceMap.set(resource, await ResourceStore.getSubresourcesForResource(resource, token));
      }
      setSubresourcesMap(subresourceMap);
    };
    fillSubresourceMap(organizationDetails?.expandableResources || [], token)
  }, [organizationDetails?.expandableResources])

  const getSubresourceEditName = useCallback((resource: string): string => {
    const subresourceName = editFieldsMap.get(resource);
    if (!subresourceName)
      return "";
    return subresourceName;
  }, [editFieldsMap]);

  const setSubresourceEditName = useCallback((resource: string, subresourceName: string) => {
    subresourceName = subresourceName.replace(/ /gi, "").replace(/:/gi, "");
    setEditFieldsMap(new Map(editFieldsMap).set(resource, subresourceName));
  }, [editFieldsMap, setEditFieldsMap])

  const addSubresource = useCallback((resource: string) => {
    const subresourceId = editFieldsMap.get(resource);
    if (!subresourceId)
      return "";
    const newMap = new Map(editFieldsMap);
    newMap.delete(resource);
    setEditFieldsMap(newMap);
    OrgStore.addSubresourceToCurrentOrg(resource + subresourceId, token);
  }, [editFieldsMap, token, setEditFieldsMap])

  return (
    <List>
      <ListItem>
        <Typography>{"Id: " + organizationDetails?.id}</Typography>
      </ListItem>
      <ListItem>
        <Typography>{"Name: " + organizationDetails?.name}</Typography>
      </ListItem>
      <ListItem>
        <Typography>{t("org.available_roles") + ":"}</Typography>
      </ListItem>
      <ListItem>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {organizationDetails?.assignableRoles.map((role) => (
            <Chip
              color={role === "ROLE_ORGANIZATION_ADMIN" ? "error" : "primary"}
              variant="outlined"
              key={role}
              label={role}
            />
          ))}
        </Stack>
      </ListItem>
      {organizationDetails?.expandableResources?.map((resource) => (
        <div key={resource} >
          <ListItem >
            <Typography>{"Resources inside " + assignableRoleToResourceName(resource) + ":"}</Typography>
          </ListItem>
          <ListItem>
            <List>
              <ListItem>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  {(subresourcesMap.get(resource))?.map((role) => (
                    <Chip
                      color={"primary"}
                      variant="outlined"
                      key={role}
                      label={role}
                    />
                  ))}
                </Stack>
              </ListItem>
              <ListItem>
                <TextField
                  value={getSubresourceEditName(resource)}
                  onChange={(e) => setSubresourceEditName(resource, e.target.value)}
                  sx={{ width: 300 }}
                  label="New subresource name"
                  inputProps={{ onKeyDown: (e) => e.stopPropagation() }}
                />
                <Button
                  onClick={() => addSubresource(resource)}
                  color="primary"
                  disabled={getSubresourceEditName(resource) === undefined || getSubresourceEditName(resource).length < 5 ||
                    subresourcesMap.get(resource)?.includes(getSubresourceEditName(resource))}
                >
                  {t("org.add_resource")}
                </Button>
              </ListItem>
            </List>
          </ListItem>
        </div>
      ))}
    </List>
  );
};

export default CurrentOrganizationDetails;
