import React from 'react';
import { Box, Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '150px', width: '100%', bgcolor: 'primary.main' }} id='footer'>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ height: '100%', width: '100%' }}
      >
        <Paper elevation={0} sx={{ bgcolor: 'inherit', width: '30%' }}>
          <Button
            variant='text'
            id="footer_about_button"
            sx={{ color: theme.palette.primary.contrastText, textTransform: 'none', width: '100%' }}
            onClick={() => navigate("/about")}
          >{
            t('footer.about')
          }</Button>
          <Button
            variant='text'
            id="footer_faq_button"
            sx={{ color: theme.palette.primary.contrastText, textTransform: 'none', width: '100%' }}
            onClick={() => navigate("/faq")}
          >{
            t('footer.faq')
          }</Button>
        </Paper>
        <Paper elevation={0} sx={{ bgcolor: 'inherit', width: '30%' }} id='footer_faq'>
          <Button
            variant='text'
            id="footer_terms_button"
            sx={{ color: theme.palette.primary.contrastText, textTransform: 'none', width: '100%' }}
            onClick={() => navigate("/terms")}
          >{
            t('footer.terms')
          }</Button>
          <Button
            variant='text'
            id="footer_privacy_button"
            sx={{ color: theme.palette.primary.contrastText, textTransform: 'none', width: '100%' }}
            onClick={() => navigate("/privacy")}>{
            t('footer.privacy')
          }</Button>
        </Paper>

        <Paper elevation={0} sx={{ bgcolor: 'inherit', width: '30%' }}>
          <Typography
            variant='caption'
            id="footer_imprint"
            color={theme.palette.primary.contrastText}
            sx={{
              whiteSpace: 'break-spaces', display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              overflow: 'hidden',
              textTransform: 'none',
              fontSize: '0.75rem',
              lineHeight: '1.2'
            }
            }>{
            t('footer.imprint')
          }</Typography>
        </Paper>
      </Stack>
    </Box>
  );
};

export default Footer;
