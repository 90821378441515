export interface IOrg {
  name: string;
  id: string;
}

export interface IOrgWithRoles {
  name: string;
  id: string;
  assignableRoles: string[];
  expandableResources? : string[];
}

export interface IOrgWithResources extends IOrgWithRoles {
  managedResources: string[];
}

export interface IPageOrg {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  content: IOrg[];
  number: number;
}

export interface ISortObject {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface IPageableObject {
  offset: number;
  sort: ISortObject;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIPageOrg(object: any): object is IPageOrg {
  console.log(object);
  return (
    "totalPages" in object &&
    "totalElements" in object &&
    "first" in object &&
    "last" in object &&
    "size" in object &&
    "content" in object &&
    "number" in object
  );
}

export default isIPageOrg;
