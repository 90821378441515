import { IOrgWithRoles } from "../models/org.types";
import {http} from "../utils/http/http";
import {getAllProfileResources} from "../services/org.service";


export function assignableRoleToResourceName(resource: string): string {
  return resource.slice("SharedProfile:".length, resource.length - 1)
}


type StringMap = {
  [key: string]: string;
};
class ResourcesStore {
  private resourceMap: StringMap = {
    SelfAdministration: "ROLE_ORGANIZATION_ADMIN",
    SharedContactList: "SharedProfile:ContactList:",
    ResearchUnit: "SharedProfile:ResearchUnit:",
    ApplicationAccess: "ROLE_USER",
    "Hygiaso SuperUser": "ROLE_ADMIN",
  };

  async getSubresourcesForResource(resource: string, token: string): Promise<string[]> {
    if (resource.startsWith("SharedProfile:") && resource.endsWith(":")) {
      const { data } = await getAllProfileResources(token);
      const resourceName = assignableRoleToResourceName(resource);
      const subresources = new Set((data as string[]).filter(r => r.startsWith("/" + resourceName +"/"))
        .map(r => {
          const parts = r.split("/");
          if (parts.length == 3)
            return parts[1]; // part 0 is always ""
          else
            return parts[1] + "/" + parts[2];
        }));

      return Array.from(subresources);
    }
    return [];
  }

  async getDetailedRolesForResource(
    resource: string,
    token: string,
  ): Promise<{roles: string[], expandableResource: boolean}> {
    // TODO: make something more elaborate and create configuration for
    //  different resource types.
    if (resource.endsWith("ContactList:")) {
      return {roles: [resource + "READ", resource + "WRITE"], expandableResource: false};
    }
    if (resource.startsWith("SharedProfile:")) {
      const subresources = await this.getSubresourcesForResource(resource, token);
      console.log(resource)
      console.log(subresources);
      const roles = subresources
        .flatMap(r => ["SharedProfile:" + r + "/**:WRITE", "SharedProfile:" + r + "/**:READ"]);

      return {roles, expandableResource: true};
    }
    
    return {roles: [], expandableResource: false};
  }

  async getResourceForRole(role: string, _token: string): Promise<string> {
    for (const key in this.resourceMap) {
      const resourceRole = this.resourceMap[key];
      if (resourceRole.endsWith(":")) {
        if (role.startsWith(resourceRole)) return key;
      } else {
        if (role === resourceRole) return key;
      }
    }

    return role;
  }

  async getAvailableResourceNames(): Promise<string[]> {
    const rv: string[] = [];
    for (const key in this.resourceMap) {
      rv.push(key);
    }
    return rv;
  }

  async resourceForName(name: string): Promise<string> {
    return this.resourceMap[name];
  }

  async resourcesForRoles(roles: string[], token: string): Promise<string[]> {
    const rv = new Set<string>();
    for (const role of roles) {
      /* eslint-disable react/no-is-mounted */
      const resource = await this.getResourceForRole(role, token);
      rv.add(resource);
    }
    return [...rv];
  }

  async expandRolesForResources(
    orgData: IOrgWithRoles,
    token: string,
  ): Promise<IOrgWithRoles> {
    let assignableRoles = orgData?.assignableRoles ?? [];
    const expandableResources: string[] = [];
    const addableResources = assignableRoles.filter((role) =>
      role.endsWith(":"),
    );

    assignableRoles = assignableRoles.filter((role) => !role.endsWith(":"));

    for (const resource of addableResources) {
      const {roles, expandableResource} = await this.getDetailedRolesForResource(resource, token)
      assignableRoles.push(...roles);
      if (expandableResource)
        expandableResources.push(resource);
    }

    return {
      ...orgData,
      assignableRoles,
      expandableResources
    };
  }
}

export default new ResourcesStore();
