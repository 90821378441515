import { Typography, Menu, MenuItem, Button, Stack } from '@mui/material';
import { Close, ExitToAppOutlined, PersonOutline } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import AuthStore from '../../stores/AuthStore';
import { observer } from 'mobx-react';
import OrgStore from "../../stores/OrgStore";

const ProfileMenu: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logOut }: IAuthContext = useContext(AuthContext)
  const [username, setUsername] = useState<string>(t('toolbar.name_unknown'));

  useEffect(() => {
    setUsername(AuthStore.getCommonName() ?? t('toolbar.name_unknown'));
  }, [AuthStore.authToken]);

  return <PopupState variant="popover" popupId="profile-popup-menu">
    {(popupState) => (
      <React.Fragment>
        <Button
          {...bindTrigger(popupState)}
          color='primary'
          variant='text'
          sx={{
            bgcolor: 'primary.light',
            margin: 8,
            textTransform: 'none'
          }}
        >
          <PersonOutline />&nbsp;{
          username
        }</Button>
        <Menu
          autoFocus={false}
          {...bindMenu(popupState)}>
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="space-between"
              sx={{ width: "100%"}}
            >
              <Typography
                color='black'
                variant='h5'
                fontWeight='bold'
                sx={{
                  paddingBottom: '15px',
                  margin: '8px'
                }}
              >{
                username
              }</Typography>
              <Button
                sx={{
                  paddingBottom: '20px',
                  paddingRight: 0,
                  marginRight: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
                startIcon={<Close />}
                onClick={() => { popupState.close() }}
              />
            </Stack>
          </MenuItem>
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
          >
            <Typography
              color='primary'
              variant='subtitle1'
              fontWeight='600'
            >{
              t("toolbar.menu.profile")
            }</Typography>
          </MenuItem>

          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
          >
            <Button
              color='primary'
              variant='text'
              fullWidth
              sx={{
                bgcolor: 'info.main',
                fontWeight: '500',
                textTransform: 'none'
              }}
            >{
              OrgStore.currentOrganisation?.name
            }</Button>
          </MenuItem>
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
            onClick={() => {
              navigate("/");
              logOut();
            }
            }>
            <Button
              color='primary'
              variant='outlined'
              sx={{
                borderRadius: '8px',
                marginX: '30px',
                width: '100%',
                textTransform: 'none',
                fontWeight: '400'
              }}>
              <ExitToAppOutlined
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit'
                }}
              />&nbsp;{
              t('toolbar.menu.logout')
            }</Button>
          </MenuItem>
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
});

export default ProfileMenu;